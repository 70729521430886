<template>
    <div style="border-radius: 8px" class="row journal-line">
        <div class="col-12 d-flex product-details-border position-relative pe-0">
            <div class="row w-100 pe-lg-0 me-1 py-2">
                <div class="col-lg-8 col-12 mb-lg-0 mb-2 mt-lg-0 mt-2">
                    <v-select
                        placeholder="Select account head"
                        v-model="item.account_head_id"
                        :options="accountHeads" 
                        label="name"
                        :reduce="name => name.id"
                    />
                    <textarea
                        v-model="item.note"
                        placeholder="Description"
                        class="form-control mt-2" 
                        rows="1"
                    >
                    </textarea>
                </div>
                <div class="col-lg-4 col-12 my-lg-0 my-2">
                    <input
                        v-model="item.credit"
                        type="number"
                        class="form-control text-end"
                        placeholder="Amount"
                    >
                </div>
            </div>
            <div 
                @click="$emit('onClose', index)"
                class="
                d-flex
                flex-column
                align-items-center
                justify-content-between
                border-start
                invoice-product-actions
                py-50
                px-25"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x cursor-pointer font-medium-3" ><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AddFormElement',
    props: {
        item: {
            type: Object
        },
        index: {
            type: Number
        },
        accountHeads: {
            type: Array
        }
    }
}
</script>
<style scoped>
.journal-line{
        border: 1px solid #f0f2f5;
    }
    .journal-line:hover{
        background: #f0f2f5;
    }
</style>

import ngoPdfPrinter from '@/services/utils/ngoPdfPrinter';
import handleBarcode from "@/services/modules/barcode";
import handleCompany from "@/services/modules/company";
import { useStore } from "vuex";

const pdfPrinterHelper = () => {
    const { exportToPDF } = ngoPdfPrinter();
    const { fetchBarcode } = handleBarcode();
    const { fetchCompanyInfo } = handleCompany();
    const store = useStore();
    const userName = store.state.user.user.name;
    var barcode = '', qrcode = '';
    var company = {};

    const generatePdf = (companyId, data, btnFunc) => {
        let qrcodeData = JSON.stringify({
            ref_no: data.voucher_no,
            ref_date: data.date,
            created_by: userName,
            created_date_time: formatDate(new Date())
        })
        var barCodeQuery = `?code=${data.voucher_no}&qrcode=${qrcodeData}`;

        fetchBarcode(barCodeQuery).then( (res) => {
            barcode = res.barcode;
            qrcode = res.qrcode;
        }).then(() => {
            fetchCompanyInfo(companyId).then((res) => {
                company = res.data
            }).then(() => {
                exportToPDF(company, data, barcode, qrcode, btnFunc)
            })
        }).catch((e) => {
            console.log('Something went wrong');
        })
    }

    const formatDate = (date) => {
        var formattedDate = date.toISOString().split('T')[0];
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var amOrPm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        var formattedTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + amOrPm;
        return formatToDdMmYy(formattedDate) + ' ' + formattedTime
    }

    const formatToDdMmYy = (date) => {
        var dat = date.split('-');
        return `${dat[2]}-${dat[1]}-${dat[0]}`;
    }

    return{
        generatePdf
    }
}

export default pdfPrinterHelper;